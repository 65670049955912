@font-face {
  font-family: 'jostbook';
  src: url('./fonts/jost-400-book-webfont.woff') format('woff2'),
  url('./fonts/jost-400-book-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'jostbold';
  src: url('./fonts/jost-700-bold-webfont.woff') format('woff2'),
  url('./fonts/jost-700-bold-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

@font-face {
  font-family: 'jostheavy';
  src: url('./fonts/jost-800-heavy-webfont.woff') format('woff2'),
  url('./fonts/jost-800-heavy-webfont.woff2') format('woff');
  font-weight: normal;
  font-style: normal;

}

*{
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: jostbook, -apple-system,BlinkMacSystemFont,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol";
  line-height: 1;
  font-size: 1.6rem;
  color: var(--90e-navy);
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  -webkit-font-feature-settings: "pnum";
  font-feature-settings: "pnum";
  font-variant-numeric: proportional-nums;
  --90e-teal: hsl(167, 68%, 54%);
  --90e-cyan: hsl(204, 64%, 75%);
  --90e-navy: hsl(223, 47%, 21%);
  --90e-grey: hsl(204,100%, 25%);
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/*Move Freshchat widget a bit up*/
#fc_frame, #fc_frame.fc-widget-normal{
  bottom: 80px !important;
}